import React from "react"
import { graphql } from 'gatsby'

import PageTemplate from '../components/pageTemplate'
import Crate from "../components/crate"
import MainContainer from "../components/mainContainer"
import Hr from "../components/horizontalRuleFull"

class RegistrationPage extends React.Component {

  render() {

    const { data } = this.props
    const locale = this.props.pageContext.locale
    let colloquiumRegistrationLink, virtualColloquiumLoginLink, virtualColloquiumRegistrationLink;

    switch (locale) {
      case 'es':
        colloquiumRegistrationLink = 'https://forms.office.com/Pages/ResponsePage.aspx?id=_C6ZjniTJkCAYnDDvQ7ZtGa5zxpH2jpMjU3iOMck1UtUNEE5S1BTU1RENFNYUU9UTTRSMThKN0hTWS4u';
        virtualColloquiumLoginLink = `https://www.educatemagis.org/${locale}/login/`
        virtualColloquiumRegistrationLink = `https://www.educatemagis.org/${locale}/join/`
        break;
      case 'fr':
        colloquiumRegistrationLink = 'https://forms.office.com/Pages/ResponsePage.aspx?id=_C6ZjniTJkCAYnDDvQ7ZtGa5zxpH2jpMjU3iOMck1UtUOTFHUzVYRDFRREdWQURJODEyOFZHWEw3VC4u';
        virtualColloquiumLoginLink = `https://www.educatemagis.org/${locale}/login/`
        virtualColloquiumRegistrationLink = `https://www.educatemagis.org/${locale}/join/`
        break;
      default:
        colloquiumRegistrationLink = 'https://forms.office.com/Pages/ResponsePage.aspx?id=_C6ZjniTJkCAYnDDvQ7ZtGa5zxpH2jpMjU3iOMck1UtUN1lYU0U3TUpXS01LWFY1NUY1R1YxUVA4Ny4u';
        virtualColloquiumLoginLink = `https://www.educatemagis.org/login/`
        virtualColloquiumRegistrationLink = 'https://www.educatemagis.org/join/';
    }

    const title = "Registration"
    const post = data.wordpressPage;

    const content = post.acf.page_registration.content;
    const colloquium = post.acf.page_registration.cta_colloquium;
    const virtualColloquium = post.acf.page_registration.cta_virtual_colloquium;

    return (

      <PageTemplate locale={locale} title={title} icon={"icon-a"} headerImage={this.props.data.headerImage}>
        <Crate>
          <MainContainer className="px-5 sm:px-0 py-3">
            <div className="flex flex-wrap -mx-5 py-10">
              <div className="w-full md:w-1/2 lg:w-6/12 px-5">
                <div className="text-osm text-gray-900" dangerouslySetInnerHTML={{ __html: content }}></div>
              </div>
              <div className="w-full md:w-1/2 lg:w-6/12 px-5">
                <div>
                  <h3 className="tk-museo mt-3 mb-1 text-mmd text-pink-900 font-bold"> {colloquium.title} </h3>
                  <p className="text-olg mt-0 mb-3 text-purple-900 font-semibold"> {colloquium.subtitle} </p>
                  {(colloquium.extra_information.length > 0) &&
                    <p className="text-osm mt-0 mb-3 text-purple-900"> {colloquium.extra_information} </p>
                  }
                  <div className="my-5">
                    <a
                      href={colloquiumRegistrationLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-pink-900 my-3 inline-block hover:bg-pink-700 text-oxs text-white font-semibold my-2 py-2 px-4 rounded-full uppercase">
                      {colloquium.button}
                    </a>
                  </div>
                </div>
                <div class="my-10">
                  <Hr />
                </div>
                <div>
                  <h3 className="tk-museo mt-3 mb-1 text-mmd text-purple-900 font-bold"> {virtualColloquium.title} </h3>
                  <p className="text-olg mt-0 mb-3 text-purple-900 font-semibold"> {virtualColloquium.subtitle} </p>
                  {(virtualColloquium.extra_information.length > 0) &&
                    <p className="text-osm mt-0 mb-3 text-purple-900"> {virtualColloquium.extra_information} </p>
                  }
                  <div className="my-5">
                    <a
                      style={{ minWidth: '120px' }}
                      href={virtualColloquiumRegistrationLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-blue-900 hover:bg-blue-700 text-oxs inline-block text-white font-semibold my-2 py-2 px-4 rounded-full uppercase text-center">
                      {virtualColloquium.button_join}
                    </a>
                    &nbsp;
                    &nbsp;
                    {/* <span className="mx-3 tk-museo text-purple-900 font-bold">
                      <FormattedMessage id="Or" />
                    </span> */}
                    <a
                      style={{ minWidth: '120px' }}
                      href={virtualColloquiumLoginLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-blue-900 hover:bg-blue-700 text-oxs inline-block text-white font-semibold my-2 py-2 px-4 rounded-full uppercase text-center">
                      {virtualColloquium.button_login}
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </MainContainer>
        </Crate>
      </PageTemplate>
    )
  }
}

export default RegistrationPage

export const query = graphql`
  query($pId: Int!, $pType: String!) {
      wordpressPage(wordpress_id: {eq: $pId}, type: {eq: $pType}) {
      title
      acf{
        page_registration{
          content
          cta_colloquium{
            title
            subtitle
            extra_information
            button
          }
          cta_virtual_colloquium{
            title
            subtitle
            extra_information
            button_login
            button_join
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "jesedu2024-plain-dark.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`